import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import TollIcon from '@mui/icons-material/Toll';
import AppsIcon from '@mui/icons-material/Apps';
import { useHistory, useLocation } from "react-router-dom";
import ModalDesktopLogin from "../components/ModalDesktopLogin";

const SubNavBarDesktop = () => {

    const history=useHistory();
    const location = useLocation();

    const data_store=[...useSelector(state => state.store_data)];
    const data_user_store=[...useSelector(state => state.list_user_store)];

    const [openModal, setOpenModal]=useState(false);

    const toggleModalDialogue=()=>setOpenModal(!openModal);

    const MenuData=[
        {
            "id_menu": 1,
            "nom": "Accueil",
            "route":"/",
        },
        {
            "id_menu": 2,
            "nom": "Mon panier",
            "route":"/cart"
        },
        {
            "id_menu": 3,
            "nom": "Mes commandes",
            "route":"/commande"
        },
        {
            "id_menu": 4,
            "nom": "Contact boutique",
            "route": "/contact"
        },
    ];

    const goToNavigate=(path)=>{
        if(path=="/commande"){
            if(data_user_store.length>0){
                history.push(path);
            }
            else{
                toggleModalDialogue();  
            }
        }
        else{
            history.push(path);
        }
    }

    const verifConnected=()=>{
        history.push("/commande");
    }

     
  return (
            <>
            <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", padding:"15px 20px", borderBottom:"solid 1px #ececec" }} >
                <div style={{  display:"flex", flexDirection:"row", alignItems:"center"  }} >
                <div style={{ display:"flex", flexDirection:"row", alignItems:"center", borderRight:"solid 1px #73787D", paddingRight:50 }} >
                    <AppsIcon  style={{ color:"#000", fontSize:24 }}  />
                    <div style={{ marginLeft:10 }} >
                        <span style={{ fontFamily:"NunitoSans-Bold", color:"#000", fontSize:18 }} >Menu principal</span>
                    </div>
                </div>
                <div style={{ display:"flex", flexDirection:"row", alignItems:"center", marginLeft:32 }} >
                    {
                        MenuData.map((item,index)=>{
                            return(
                                <React.Fragment key={index+120098332}>
                                    {
                                        location.pathname==item.route ? (
                                            <div  style={{ padding:"0px 15px", cursor:"pointer" }} >
                                                <span style={{ fontFamily:"NunitoSans-Bold", color:data_store[0].color, fontSize:14 }} >{item.nom}</span>
                                            </div> 
                                        ):(
                                            <div onClick={()=>goToNavigate(item.route)}  style={{ padding:"0px 15px", cursor:"pointer" }} >
                                                <span style={{ fontFamily:"NunitoSans-SemiBold", color:"#aaa", fontSize:14 }} >{item.nom}</span>
                                            </div> 
                                        )
                                    }
                                
                                </React.Fragment>
                            )
                        })
                    }
                </div>
                </div>
                <div style={{ display:"flex", flexDirection:"row", alignItems:"center", borderLeft:"solid 1px #73787D", paddingLeft:30 }} >
                    <TollIcon  style={{ color:"#000", fontSize:18 }}  />
                    <div style={{ marginLeft:5 }} >
                        <span style={{ fontFamily:"NunitoSans-SemiBold", color:"#000", fontSize:13 }} >Monnaie:</span>
                    </div>
                    <div style={{ marginLeft:5 }} >
                        <span style={{ fontFamily:"NunitoSans-Bold", color:"#000", fontSize:13 }} >FCFA</span>
                    </div>
                </div>
            </div>
            {
                (openModal) && <ModalDesktopLogin  openModal={openModal} toggleModalDialogue={toggleModalDialogue} goToCheckout={verifConnected}  />
            }
            </>
  )
}

export default SubNavBarDesktop;