import React from 'react';
import { useSelector } from "react-redux";
import Loading from "./views/Loading";
import Home from "./views/Home"
import Cart from "./views/Cart";
import Contact from "./views/Contact";
import Account from './views/Account';
import Commande from "./views/Commande";
import CheckOut from "./views/CheckOut";
import DetailCommande from "./views/DetailCommande";
import SuccessOrder from "./views/SuccessOrder";
import Paiement from "./views/Paiement";
import NavBarDesktop from "./components/NavBarDesktop";
import SubNavBarDesktop from "./components/SubNavBarDesktop";
import { useScreenDetector } from "./hooks/useMediaQuery";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

const RootNavigator = () => {

    const loading_store_data = useSelector(state => state.store_data);
    const _is_paiement = useSelector(state => state.is_paiement);
    const { isMobile, isTablet, isDesktop } = useScreenDetector();

    return (
             <main>
                {
                loading_store_data.length>0?(
                    <>
                    {
                        !_is_paiement && (
                            <BrowserRouter>
                            {
                                isMobile ?  
                                (
                                    <>
                                    
                                    <Switch>
                                        <Route path="/" exact render={()=><Home  />}  />
                                        <Route path="/cart" exact render={()=><Cart  />}  />
                                        <Route path="/contact" exact render={()=><Contact  />}  />
                                        <Route path="/account" exact render={()=><Account  />}  /> 
                                        <Route path="/checkout" exact render={()=><CheckOut  />}  /> 
                                        <Route path="/commande" exact render={()=><Commande  />}  /> 
                                        <Route path="/detail_commande" exact render={()=><DetailCommande  />}  /> 
                                        <Route path="/sussess_order" exact render={()=><SuccessOrder  />}  />
                                        <Redirect from="*" to="/"  />
                                    </Switch>
                                    
                                    </>
                                ):(
                                    <>
                                    <div style={{ boxSizing:"border-box" }} >
                                    <div style={{ position:"fixed", top:0, left:0, right:0, background:"#fff", zIndex:1000 }} >
                                        <NavBarDesktop />
                                        <SubNavBarDesktop  />
                                    </div>

                                    <div style={{ padding:"15px 20px", display:"flex", flex:1, flexDirection:"row", background:"#f1f1f2", marginTop:120, height:90+"vh"  }} >
                                        
                                    <Switch>
                                        <Route path="/" exact render={()=><Home  />}  />
                                        <Route path="/cart" exact render={()=><Cart  />}  />
                                        <Route path="/contact" exact render={()=><Contact  />}  />
                                        <Route path="/account" exact render={()=><Account  />}  /> 
                                        <Route path="/checkout" exact render={()=><CheckOut  />}  /> 
                                        <Route path="/commande" exact render={()=><Commande  />}  /> 
                                        <Route path="/detail_commande" exact render={()=><DetailCommande  />}  /> 
                                        <Route path="/sussess_order" exact render={()=><SuccessOrder  />}  />
                                        <Redirect from="*" to="/"  />
                                    </Switch>
                                        
                                    </div>
                                    </div>
                                    </>
                                )
                            }
                            </BrowserRouter>
                        )
                    }
                    {
                        _is_paiement && (
                            <Paiement  />
                        )
                    }
                    </> 
                    ):
                    (
                        <Loading  />
                    )
                }
             </main>
    )
}

export default RootNavigator;