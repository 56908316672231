import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from "react-redux";
import MuiAlert from '@mui/material/Alert';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { Rating } from 'react-simple-star-rating';
import StarIcon from '@mui/icons-material/Star';
import { UUID, formatNumber } from "../functions/General";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={3} ref={ref} variant="filled" {...props} />;
});

export default function ModalDetailDesktopProduct({ color, openDetailModal, toggleOpenDetailModal, closeModalDesktopDialogue, id_display_product, increaseProductCart, addProductToCart, discreaseProductCart, _editProductRating }) {

  const toggleDrawer = () => {
    toggleOpenDetailModal();
  };

  const stock_product=[...useSelector(state => state.product_list)];
  const data_user_store_rating=[...useSelector(state => state.list_user_store_rating)];
  let _isRefresh=useSelector(state => state.isRefresh);
  const [detail_product, setDetailProduct] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openUpdateCart, setOpenUpdateCart] = React.useState(false);
  const [removeCart, setRemoveCart] = React.useState(false);

  useEffect(()=>{
    let data=stock_product.find(item=>item.id_stock==id_display_product);
    setDetailProduct(data);
  }, [_isRefresh]);

  const handleClose = () => closeModalDesktopDialogue();

  const addTocart=()=>{
    let product={ id_tab_product:UUID(), id_stock:detail_product.id_stock, id_product:detail_product.id_product, prix_vente:detail_product.prix_vente, description:detail_product.description, quantite:detail_product.quantite, icone_product:detail_product.icone_product, qty:1, price:detail_product.prix_vente, unite_vente:detail_product.unite_vente };
    addProductToCart(product);
    setOpen(true);
}

const editProductRating=()=>{
    _editProductRating(detail_product.id_stock);
}

const _increaseProductCart=()=>{
   increaseProductCart(detail_product.id_stock)
   setOpenUpdateCart(true);
}

const _discreaseProductCart=()=>{
    discreaseProductCart(detail_product.id_stock,detail_product.qty);
    if(detail_product.qty==1){
      setRemoveCart(true);
    }
    else{
     setOpenUpdateCart(true);
    } 
}

const handleCloseUpdate=(event, reason)=>{
    if (reason === 'clickaway') {
        return;
    }
    setOpenUpdateCart(false);
 }

const _handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};

const handleCloseCart=(event, reason)=>{
  if (reason === 'clickaway') {
      return;
  }
  setRemoveCart(false);
}

  const DrawerList = (
    <Box sx={{ width: 380 }} role="presentation" onClick={toggleDrawer()}>
        <header className='headerMobile' >
        <div className='searchNavBar' >
            <div  onClick={handleClose}  style={{  marginTop:3, backgroundColor:"#f0f0f0", display:"flex", width:35, height:35, borderRadius:10, alignItems:"center", justifyContent:"center", cursor:"pointer" }} >
                <span><CloseRoundedIcon  style={{ color:"#222", fontSize:20, marginTop:2,  }}  /></span>
            </div>
            <div>
                <span style={{ fontFamily:"NunitoSans-Bold", color:"#222", fontSize:16}} >Détail de la commande</span>
            </div>
            <div  style={{ marginTop:3, position:"relative", width:40 }} >

            </div>
        </div>
        </header>
            <div style={{ width:"100%" }} >
                {
                    detail_product && (
                        <>
                        <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", height:200, marginTop:60}} >
                            <img src={detail_product.icone_product} style={{ width:150, height:150, borderRadius:5 }}  alt='images'  />
                        </div>
                        <div style={{padding:"3px 20px", }}>
                            <span style={{ fontFamily:"NunitoSans-Bold", color:"#666", fontSize:14,  }} >{detail_product.description}</span> 
                        </div>
                        <div style={{padding:"3px 20px", }}>
                            <span style={{ fontFamily:"NunitoSans-Bold", color:"#222", fontSize:16,  }} >{formatNumber(detail_product.prix_vente)} FCFA</span> 
                        </div>
                        <div style={{padding:"3px 20px", }}>
                            {
                                parseInt(detail_product.quantite)>0 && (
                                    <span style={{ fontFamily:"NunitoSans-Bold", color:"#aaa", fontSize:13,  }} > Disponibilité ({detail_product.quantite+" "+detail_product.unite_vente})</span> 
                                )
                            }
                            {
                                parseInt(detail_product.quantite)==0 && (
                                    <span style={{ fontFamily:"NunitoSans-Bold", color:"#ccc", fontSize:13,   }} > Disponibilité (Stock épuisé) </span> 
                                )
                            }
                            {
                                parseInt(detail_product.quantite)<0 && (
                                    <span style={{ fontFamily:"NunitoSans-Bold", color:"#aaa", fontSize:13,   }} > Disponibilité (Illimité) </span> 
                                )
                            }
                        </div>
                        <div style={{ marginTop:2, display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", position:"relative" }} >
                        <div style={{ padding:"3px 20px", position:"relative" }} >
                            <Rating size={18} initialValue={detail_product.nb_etoile} />
                            <div style={{ position:"absolute", top:0, left:0, right:0, bottom:0, backgroundColor:"transparent" }} >

                            </div>
                        </div>
                        </div>
                        <div  style={{  display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", padding:"3px 20px" }} >
                            <div  style={{  display:"flex", flexDirection:"row", alignItems:"center", }}  >
                                <StarIcon  style={{ fontSize:16, color:color }} />
                                <span style={{ fontFamily:"NunitoSans-Bold", color:"#000", fontSize:11,   }} >({data_user_store_rating.filter(item=>item.id_stock==detail_product.id_stock).length} avis)</span>
                            </div>
                            <div onClick={()=>editProductRating()} style={{ cursor:"pointer" }} >
                                <span style={{ fontFamily:"NunitoSans-Bold", color:"#000", fontSize:11, textDecoration:"underline"  }} >Noter le produit</span>
                            </div>
                        </div>
                        <div style={{padding:"5px 20px",  }}>
                            <div style={{ paddingTop:10, display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between" }} >
                                {
                                !detail_product.isCart && (
                                        <>
                                        {
                                            parseInt(detail_product.quantite)==0 && (
                                                <div style={{ width:"100%", height:35, backgroundColor:color, opacity:0.4, borderRadius:4, display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", border:`solid 1px ${color}`, cursor:"pointer" }} >
                                                    <ShoppingBasketIcon  style={{ color:"#fff", fontSize:14, marginRight:3 }}  />
                                                    <span style={{ fontFamily:"NunitoSans-Bold", color:"#fff", fontSize:11,  }} >ACHETER</span>
                                                </div>
                                            )
                                        }
                                        {
                                            parseInt(detail_product.quantite)!=0 && (
                                                <div onClick={addTocart} style={{ width:"100%", height:35, backgroundColor:color, opacity:1, borderRadius:4, display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", border:`solid 1px ${color}`, cursor:"pointer" }} >
                                                    <ShoppingBasketIcon  style={{ color:"#fff", fontSize:14, marginRight:3 }}  />
                                                    <span style={{ fontFamily:"NunitoSans-Bold", color:"#fff", fontSize:11,  }} >ACHETER</span>
                                                </div>
                                            )
                                        }
                                        </>
                                    )
                                }
                                {
                                    detail_product.isCart && (
                                        <div style={{ width:"100%", height:35, borderRadius:4, display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between",  }} >
                                            <div style={{height:35, width:35, borderRadius:5, backgroundColor:color, display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer" }} onClick={()=>_discreaseProductCart()} ><RemoveRoundedIcon style={{ color:"#fff", fontSize:24 }} /></div>
                                            <div><span  style={{ fontFamily:"NunitoSans-Bold", color:"#222", fontSize:12,  }}  >{detail_product.qty} {detail_product.unite_vente}</span></div>
                                            {
                                                (parseInt(detail_product.quantite)>0 && detail_product.qty==parseInt(detail_product.quantite)) ? (
                                                    <div style={{height:35, width:35, borderRadius:5, backgroundColor:color, opacity:0.4, display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer" }}  ><AddRoundedIcon style={{ color:"#fff", fontSize:24 }} /></div>
                                                ):(
                                                    <div style={{height:35, width:35, borderRadius:5, backgroundColor:color, display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer" }}  onClick={()=>_increaseProductCart()}  ><AddRoundedIcon style={{ color:"#fff", fontSize:24 }} /></div>
                                                )
                                            }
                                        </div>
                                    )
                                } 
                            </div>
                        </div> 
                        </>
                    )
                }
            </div>
            
            <Snackbar open={open} autoHideDuration={3000} onClose={_handleClose} >
            <Alert onClose={_handleClose} severity="success" sx={{ width: '100%' }}>
            Produit ajouté au panier
            </Alert>
            </Snackbar>
            <Snackbar open={openUpdateCart} autoHideDuration={3000} onClose={handleCloseUpdate} >
                <Alert onClose={handleCloseUpdate} severity="success" sx={{ width: '100%' }}>
                Panier mis à jour avec succès
                </Alert>
            </Snackbar>
            <Snackbar open={removeCart} autoHideDuration={3000} onClose={handleCloseCart} >
                <Alert onClose={handleCloseCart} severity="success" sx={{ width: '100%' }}>
                Produit rétiré avec succès
                </Alert>
            </Snackbar>
        </Box>
  );

  return (
      <Drawer open={openDetailModal} onClose={()=>closeModalDesktopDialogue()}  anchor="right" >
        {DrawerList}
      </Drawer>
  );
}