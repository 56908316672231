import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import { formatNumber } from "../functions/General";
import moment from "moment"
import { _findDetailCommandeById } from "../functions/requestCommande";

export default function DetailListOrderDesktop({color,open,_toggleDrawer, closeDrawer, detail_commande, status_commande}) {

  const toggleDrawer = () => {
    _toggleDrawer();
  };

  const [detailCommande, setDetailCommande]=useState([]);
  const [loader, setLoader]=useState(false);

  useEffect( () => {
        async function selectDetailCommandeData(){
        setLoader(true);
        let _data_commande=await _findDetailCommandeById(detail_commande.id_commande);
        setDetailCommande(_data_commande);
        setLoader(false);
    }
    selectDetailCommandeData();
  }, []); 

  const DrawerList = (
    <Box sx={{ width: 380 }} role="presentation" onClick={toggleDrawer()}>
      <div>
        <header className='headerMobile' >
        <div className='searchNavBar' >
            <div onClick={()=>closeDrawer()}  style={{  marginTop:3, backgroundColor:"#f0f0f0", display:"flex", width:35, height:35, borderRadius:10, alignItems:"center", justifyContent:"center", cursor:"pointer" }} >
                <span><CloseRoundedIcon  style={{ color:"#222", fontSize:20, marginTop:2,  }}  /></span>
            </div>
            <div>
                <span style={{ fontFamily:"NunitoSans-Bold", color:"#222", fontSize:16}} >Détail de la commande</span>
            </div>
            <div  style={{ marginTop:3, position:"relative", width:40 }} >

            </div>
        </div>
        </header>
        <div className='containCart' style={{ marginTop:-12, backgroundColor:"#fff" }} >
            
            <div style={{ backgroundColor:"#fff", paddingTop:10, paddingBottom:12, paddingLeft:12, paddingRight:12, borderBottom:"solid 1px #fff", }} >
                <div style={{ display:"flex", flexDirection:"row"}} >
                    {
                        status_commande==1 && (
                            <div  style={{paddingLeft:2, paddingRight:2, paddingBottom:2, height:20, width:60, borderRadius:5, backgroundColor:"#4caf50", marginLeft:0, display:"flex", alignItems:"center", justifyContent:"center" }}  >
                                <div><span style={{fontSize:9, color:"#fff", fontFamily:"NunitoSans-Bold"}} > Payé </span></div>
                            </div>
                        )
                    }
                    {
                        status_commande==0 && (
                            <div  style={{paddingLeft:2, paddingRight:2, paddingBottom:2, height:20, width:60, borderRadius:5, backgroundColor:"#ddd", marginLeft:0, display:"flex", alignItems:"center", justifyContent:"center" }}  >
                                <div><span style={{fontSize:9, color:"#fff", fontFamily:"NunitoSans-Bold"}} > en attente </span></div>
                            </div>
                        )
                    }
                </div>
                <div>
                <div style={{ marginTop:5 }} ><span style={{fontSize:14, fontFamily:"NunitoSans-Bold", color:"#222" }} ><span style={{fontSize:15, marginTop:5, fontFamily:"NunitoSans-Bold", color:"#222" }} >Commande </span> #{detail_commande.number_commande} </span></div>
                <div style={{ marginTop:3 }} ><span style={{fontSize:12, fontFamily:"NunitoSans-Bold", color:"#bbb" }} >Enregistrée le : {moment(parseInt(detail_commande.date_enregistrement)).format("DD-MM-YYYY")} </span></div>
                <div style={{ display:"flex", flexDirection:"row", alignItems:"center" }} >
                    <div><span style={{fontSize:12, marginTop:3, fontFamily:"NunitoSans-Bold", color:"#333" }} >Nombres d'article </span></div>
                    <div style={{ width:25, height:17, backgroundColor:color, display:"flex", alignItems:"center", justifyContent:"center", marginTop:3, marginLeft:3, borderRadius:10 }} >
                        <span style={{fontSize:9, marginTop:0, fontFamily:"NunitoSans-Bold", color:"#fff" }} > {detail_commande.nb_article} </span>
                    </div>
                </div>
                <div style={{ marginTop:3, }} ><span style={{fontSize:12, fontFamily:"NunitoSans-Bold", color:"#333" }} >Total: {formatNumber(detail_commande.montant)} Fcfa</span></div>
                </div>
            </div>
            <div style={{  padding:"12px 15px", marginTop:0, marginBottom:5, backgroundColor:"#f5f5f5"  }}  >
                <div><span style={{ fontFamily:"NunitoSans-Bold", color:"#75757a", fontSize:12,  }} >CONTENU DE LA COMMANDE</span></div>
            </div>
            {
                loader ? (
                    <div style={{ display:"flex", width:"100%", alignItems:"center", justifyContent:'center' }} >
                        <CircularProgress style={{ color:color, marginTop:100 }} />
                    </div>
                ):(
                <div>
                    {
                        detailCommande.length>0 && detailCommande.sort((a, b)=>(b.date_enregistrement-a.date_enregistrement)).map((item,index)=>{
                            return(
                                <div key={index} style={{ padding:"5px 10px", marginTop:5, background:"#fff", marginLeft:5, marginRight:5, borderBottom:"solid 1px #ececec",  }} >
                                    <div style={{ display:"flex", flexDirection:"row", alignItems:"center",  paddingBottom:10 }} >
                                        <div style={{ width:80, height:80, display:"flex", alignItems:"center", justifyContent:"center", background:"#fff" }} >
                                            <img src={item.icone_product} style={{ width:70, height:70 }} alt='images'  />
                                        </div>
                                        <div style={{ marginLeft:10 }} >
                                        <div><span style={{ fontFamily:"NunitoSans-Bold", color:"#282828", fontSize:12,  }} >{item.description}</span></div>
                                        <div style={{ marginTop:3, display:"flex", flexDirection:"row", alignItems:"center" }} > 
                                                <div style={{ marginLeft:3 }} ><span style={{ fontFamily:"NunitoSans-Bold", color:"#aaa", fontSize:10,  }} >Quantité: 1</span></div>
                                            </div>
                                        <div style={{ marginTop:3 }} ><span style={{ fontFamily:"NunitoSans-Bold", color:"#333", fontSize:14,  }} >{formatNumber(item.montant)} FCFA</span></div> 
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                )
            }

        </div>
    </div>
    </Box>
  );

  return (
      <Drawer open={open} onClose={()=>closeDrawer()}  anchor="right" >
        {DrawerList}
      </Drawer>
  );
}