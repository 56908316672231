import React, { useState } from 'react';
import { useDispatch, useSelector, batch } from "react-redux";
import { Search } from "react-feather";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import shopping_cart from "../assets/images/shopping_cart.svg";
import phone_call from "../assets/images/phone_call.svg";
import { useHistory } from "react-router-dom";
import { cutString } from '../functions/General';
import { additionCartMontant, formatNumber } from "../functions/General";
import ModalDetailDesktopProduct from "../components/ModalDetailDesktopProduct";
import ModalDesktopLogin from "../components/ModalDesktopLogin";
import ModalDesktopRating from "../components/ModalDesktopRating";
import { useScreenDetector } from "../hooks/useMediaQuery";
import { _saveRating, _findUserUserStoreProductRating, _updateProductRating, _updateProductStockRating, _findUserProductRatingByStockId } from "../functions/requestRating";
import { ADD_CART, UPDATE_STOCK_PRODUCT, ADD_USER_STORE_RATING, UPDATE_CART, REFRESH_VIEW, GET_USER_COORDINATES } from "../lib/actions/action_type";

const NavBarDesktop = () => {

    const history=useHistory();
    const dispatch=useDispatch();
    const cartData=[...useSelector(state => state.cart_list)];
    const data_store=[...useSelector(state => state.store_data)];
    const stock_product=[...useSelector(state => state.product_list)];
    const data_user_store=[...useSelector(state => state.list_user_store)];
    let _isRefresh=useSelector(state => state.isRefresh);
    const [data_product, setDataProduct]=useState([]);
    const [search_value, setSearchValue]=useState("");
    const [openDetailModal, setOpenDetailModal]=useState(false);
    const [id_display_product, setIdDisplayProduct]=useState(0);
    const [other_data_product, setOtherDataProduct]=useState([]);
    const [openModal, setOpenModal]=useState(false);
    const [typePage, setTypePage]=useState("");
    const [openDetailModalRating, setOpenDetailModalRating]=useState(false);
    const [loading, setLoading]=useState(false);

    const { isMobile, isTablet, isDesktop } = useScreenDetector();

    const toggleModalDialogue=()=>setOpenModal(!openModal);

    const toggleOpenDetailModalRating=()=>setOpenDetailModalRating(!openDetailModalRating);

    const addProductToCart=(data)=>{
        let _listProduct=stock_product.map(item=>item.id_stock==data.id_stock?{ ...item, qty:data.qty, isCart:true }: item);
        let _data_product=data_product.map(item=>item.id_stock==data.id_stock?{ ...item, qty:data.qty, isCart:true }: item);
        setDataProduct(_data_product);
        setOtherDataProduct(_data_product); 
        batch(()=>{
             dispatch({type:ADD_CART, payload:[data]});
             dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
             dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
        });
     }
  
    const increaseProductCart=(id_stock)=>{
          let newCart=cartData.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty+1, price:parseInt(item.prix_vente)*(item.qty+1) }: item);
          let _listProduct=stock_product.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty+1, isCart:true }: item);
          let _data_product=data_product.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty+1, isCart:true }: item);
          setDataProduct(_data_product);
          setOtherDataProduct(_data_product);
          batch(()=>{
              dispatch({type:UPDATE_CART, payload:newCart});
              dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
              dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
          });
    }
  
    const discreaseProductCart = (id_stock,qty) =>{
        if(qty<=1){
          let newCart=cartData.filter(item=>item.id_stock!=id_stock);
          let _listProduct=stock_product.map(item=>item.id_stock==id_stock?{ ...item, qty:0, isCart:false }: item);
          let _data_product=data_product.map(item=>item.id_stock==id_stock?{ ...item, qty:0, isCart:false }: item);
          setDataProduct(_data_product);
          setOtherDataProduct(_data_product);
          batch(()=>{
              dispatch({type:UPDATE_CART, payload:newCart});
              dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
              dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
          });
        }
        else if(qty>1){
          let newCart=cartData.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty-1, price:parseInt(item.prix_vente)*(item.qty-1) }: item);
          let _listProduct=stock_product.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty-1, isSelect:true }: item);
          let _data_product=data_product.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty-1, isSelect:true }: item);
          setDataProduct(_data_product);
          setOtherDataProduct(_data_product);
          batch(()=>{
              dispatch({type:UPDATE_CART, payload:newCart});
              dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
              dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
          });
        }
    }

    const _editProductRating=(id_stock)=>{
        setTypePage("rating");
        setIdDisplayProduct(id_stock)
        if(data_user_store.length>0){
            toggleOpenDetailModalRating();
        }
        else{
            toggleModalDialogue();  
        } 
    }

    const toggleOpenDetailModal=()=>setOpenDetailModal(!openDetailModal);

    const toggleModalDesktopDialogue=()=>{
        setOpenDetailModal(true);
    }

    const closeModalDesktopDialogue=()=>{
        setOpenDetailModal(false);
    }

    const goToHome=()=>{
        history.push("/");
    }

    const goToCart=()=>{
        history.push("/cart");
    }

    const goToContactPage=()=>{
        history.push("/contact");
    }

    const displayDetailProduct=(id_stock)=>{
        setSearchValue("");
        setDataProduct([]);
        setIdDisplayProduct(id_stock)
        toggleOpenDetailModal();
    }
    
    const searchProduct = value => {
        setSearchValue(value);
        const filteredProduct = stock_product.filter(product => {
          let searchTermLowercase = value.toLowerCase();
          if( product && typeof(product.description)!= 'undefined'){
            let productLowercase = product.description.toLowerCase();
            return productLowercase.indexOf(searchTermLowercase) > -1;
          }
        });
        setDataProduct(filteredProduct); 
    }

    const verifConnected=()=>{
        if(typePage=="commande"){
            history.push("/commande");
        }
        else if(typePage=="rating"){
        //    toggleOpenDetailModalRating();
        }
    }

    const _addProductRating=async(nb_etoile, id_stock)=>{
        setLoading(true);
        let _nb_etoile=parseFloat(nb_etoile/20);
        let tab_rating = await _findUserUserStoreProductRating(data_user_store[0].id_client_store, id_stock);
        if(tab_rating.length<=0){
            let _data_rating={id_user: data_store[0].id_user,id_stock:id_stock,id_store:data_store[0].id_local_store,nb_etoile:_nb_etoile,date_enregistrement:(Date.now()).toString(),id_client_store:data_user_store[0].id_client_store,nom_client_store:data_user_store[0].nom,telephone_client_store:data_user_store[0].numero_telephone,email_client_store:data_user_store[0].email}
            let _id_product_rating=await _saveRating(_data_rating);
            let data_product_rating=await _findUserProductRatingByStockId(id_stock);
            let moy_nb_rating=parseFloat(additionCartMontant(data_product_rating, 0, 'nb_etoile')/data_product_rating.length);
            let data_update_rating={id_stock: id_stock, nb_etoile: moy_nb_rating }
            await _updateProductStockRating(data_update_rating);
            _data_rating.id_product_rating=_id_product_rating;
            let _listProduct=stock_product.map(item=>item.id_stock==id_stock?{ ...item, nb_etoile:moy_nb_rating }: item);
            setDataProduct(_listProduct);
            batch(()=>{
                dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
                dispatch({type:ADD_USER_STORE_RATING, payload:[_data_rating]});
                dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
            });
            setLoading(false);
        }
    }

    const editProductRating=async(id_product_rating,nb_etoile,id_stock)=>{
        setLoading(true);
        let _nb_etoile=parseFloat(nb_etoile/20);
        let _data_rating={ id_product_rating:id_product_rating, nb_etoile:_nb_etoile, id_stock:id_stock };
        let _id_product_rating=await _updateProductRating(_data_rating);
        let data_product_rating=await _findUserProductRatingByStockId(id_stock);
        let moy_nb_rating=parseFloat(additionCartMontant(data_product_rating, 0, 'nb_etoile')/data_product_rating.length);
        let data_update_rating={id_stock: id_stock, nb_etoile: moy_nb_rating }
        await _updateProductStockRating(data_update_rating);
        let _listProduct=stock_product.map(item=>item.id_stock==id_stock?{ ...item, nb_etoile:moy_nb_rating }: item);
        setDataProduct(_listProduct);
        batch(()=>{
            dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
            dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
        });
        setLoading(false);
    }
     
  return (
    <>
            <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", padding:"15px 20px", borderBottom:"solid 1px #ececec" }} >
                <div  onClick={()=>goToHome()}  style={{ display:"flex", flexDirection:"row", alignItems:"center", cursor:"pointer"}}  >
                    <LocalMallIcon  style={{ color:data_store[0].color, fontSize:36 }}   />
                    <span style={{ fontFamily:"KalekoHeavy", color:"#000", fontSize:30, marginLeft:5 }} >{cutString(data_store[0].nom, 10)}</span> 
                </div>
                <div style={{ position:"relative", }} >
                    <div style={{  position:"relative", marginLeft:15, marginRight:15, width:760, }} >
                        <button  style={{ position:"absolute", height:35, width:35, background:data_store[0].color, borderRadius:5, border:`solid 1px ${data_store[0].color}`, right:5, top:6, color:"#bbb" }} >
                            <Search size={20} style={{ color:"#fff" }}  />
                        </button>
                        <input
                            className='search_input'
                            type="text"
                            style={{border:`2px solid ${data_store[0].color}`,background: "#fff",width: "100%",  height:48,boxSizing: "border-box",borderRadius:5,color: "#333",fontSize:14,outline: "none",overflow: "hidden",zIndex: 1,paddingRight:35,paddingLeft:15, fontFamily:"NunitoSans-SemiBold" }}
                            placeholder="Rechercher un produit..."
                            onChange={e=>searchProduct(e.target.value)}
                            value={search_value}
                        />
                    </div>
                    {
                        (data_product.length>0 && search_value!="") && (
                            <div style={{  position:"absolute", marginLeft:15, marginRight:15, width:760, background:"#fff", padding:"15px 0px", borderRadius:4, boxShadow:"0 4px 8px 0 rgba(0, 0, 0, .15)" }} >
                                {
                                    data_product.map((item,index)=>{
                                        return(
                                            <div onClick={()=>displayDetailProduct(item.id_stock)} className="item-list-search" key={index} style={{  padding:"5px 15px", cursor:"pointer" }} >
                                                <span style={{ fontFamily:"NunitoSans-SemiBold", color:"#333", fontSize:16 }} >{item.description}</span>
                                            </div>  
                                        )
                                    })
                                }        
                            </div>
                        )
                    }
                </div>
                <div style={{ display:"flex", flexDirection:"row", alignItems:"center",  }} >
                    <div onClick={()=>goToCart()} style={{ width:70, height:50, alignItems:"center", justifyContent:"center", textAlign:"center", marginRight:10, position:"relative", cursor:"pointer" }} >
                        <img src={shopping_cart} alt='' style={{ width:26, marginTop:5 }}  />
                        <div style={{ marginTop:-5 }} >
                            <span style={{ fontFamily:"NunitoSans-SemiBold", color:"#000", fontSize:10 }} >Mon panier</span>
                        </div>
                        <div style={{ position:"absolute", width:20, height:20, right:10, top:-5, background:data_store[0].color, borderRadius:16, display:"flex", alignItems:"center", justifyContent:"center"  }} >
                            <span style={{ fontFamily:"NunitoSans-Bold", color:"#ffffff", fontSize:10 }} >{cartData.length}</span>
                        </div>
                    </div>
                    <div onClick={()=>goToContactPage()}  style={{ width:70, height:50, alignItems:"center", justifyContent:"center", textAlign:"center", marginRight:10, position:"relative", cursor:"pointer" }} >
                        <img src={phone_call} alt='' style={{ width:26, marginTop:5 }}  />
                        <div style={{ marginTop:-5 }} >
                            <span style={{ fontFamily:"NunitoSans-SemiBold", color:"#000", fontSize:10 }} >Contact</span>
                        </div>
                    </div>
                </div>
            </div>
            {
                (openDetailModal && id_display_product>0 && !isMobile) && <ModalDetailDesktopProduct openDetailModal={openDetailModal} color={data_store[0].color} toggleOpenDetailModal={toggleModalDesktopDialogue}  closeModalDesktopDialogue={closeModalDesktopDialogue}  id_display_product={id_display_product} increaseProductCart={increaseProductCart} addProductToCart={addProductToCart}  discreaseProductCart={discreaseProductCart} _editProductRating={_editProductRating} /> 
            }
            {
                (openModal) && <ModalDesktopLogin  openModal={openModal} toggleModalDialogue={toggleModalDialogue} goToCheckout={verifConnected}  />
            }
            {
                (openDetailModalRating && id_display_product>0 && !isMobile) && <ModalDesktopRating color={data_store[0].color} openDetailModalRating={openDetailModalRating} toggleOpenDetailModalRating={toggleOpenDetailModalRating}  id_display_product={id_display_product} _addProductRating={_addProductRating}  editProductRating={editProductRating}  />
            }
            </>
  )
}

export default NavBarDesktop;