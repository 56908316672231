import axios from 'axios';

export const sendNotification = async (data) =>{
    try {
        let res = await  axios({
            method: 'POST',
            baseURL:"https://api.keiwa.co",
            url: '/send_notification',
            data:{
                title:data.title,
                description:data.description,
                token:data.token,
                id_user:data.id_user
            },
            timeout: 60000,
            headers: {"Access-Control-Allow-Origin":'*','X-Custom-Header': 'foobar'},
            responseType: 'json',
          })
          return res.data;
    } catch (error) {
        return error;
    }
}

export const recharge_wallet = async (data) =>{
    try {
        let res = await  axios({
            method: 'POST',
            baseURL:"https://api.keiwa.co",
            url: '/api/mobilemoney/wallet/rechargement/',
            data:{
                amount:data.amount,
                user_msisdn:data.user_msisdn,
                mno_name:data.mno_name,
                id_user:data.id_user,
                libelle:data.libelle,
                description:data.description,
                type_operation:1,
                type_rechargement:"RECHARGE_WALLET"
            },
            timeout: 60000,
            headers: {"Access-Control-Allow-Origin":'*','X-Custom-Header': 'foobar'},
            responseType: 'json',
          });
          return res.data;
    } catch (error) {
        return error;
    }
}