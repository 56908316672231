import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const CategoryItemDesktop = ({nom_category,id_local_category, isSelect, tag, color,_choiceCategoryDesktop}) => {

  const choiceCategoryDesktop=()=>{
    _choiceCategoryDesktop(id_local_category, tag);
  }
     
  return (
        <div onClick={()=>choiceCategoryDesktop()} style={{ display:"flex", flexDirection:"row", alignItems:"center", marginBottom:15, cursor:"pointer" }} >
            {
              !isSelect && (
                <div style={{ width:18, height:18, borderRadius:4, border:"solid 2px #E1E1E1" }} >
                </div>
              )
            }
            {
              isSelect && (
                <div style={{ width:18, height:18, borderRadius:4, border:`solid 2px ${color}`, backgroundColor:color, display:"flex", alignItems:"center", justifyContent:"center" }} >
                  <CheckOutlinedIcon style={{ color:"#fff", fontSize:20 }} />
                </div>
              )
            }
            <div style={{ marginLeft:10 }} >
                <span style={{ fontFamily:"NunitoSans-SemiBold", color:"#000", fontSize:12.5 }} >{nom_category}</span>
            </div>
        </div>
  )
}

export default CategoryItemDesktop;